import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip } from "recharts";
import { BiRectangle } from "react-icons/bi";
import axios from "../../../../../axios-base";
import { useSelector } from "react-redux";




// CardTitle Component
const CardTitle = ({ name, color, alignment }) => (
  <h2
    className={`text-${color}-600 text-lg font-bold text-${alignment}`}
  >
    {name}
  </h2>
);

// CardBody Component
const CardBody = ({ name, color }) => (
  <h3
    className={`text-${color}-500 text-md font-semibold`}
  >
    {name}
  </h3>
);

// CardSubText Component
const CardSubText = ({ name }) => (
  <p className="text-gray-700 text-sm">{name}</p>
);

// StatsComponent
const StatsComponent = () => {
  const activityData = [
    { name: "Tests", value: 400, color: "#8884d8", fill: "#8884d8" },
    { name: "Summary Notes", value: 300, color: "#82ca9d", fill: "#82ca9d" },
    { name: "Question of the day", value: 300, color: "#ffc658", fill: "#ffc658" },
  ];
  // set state variables to store the data from the API
  const [activityData_, setActivityData] = useState([]);
  const userAuth = useSelector((state) => state.auth);
  // load the data from the API at first render then every 30 mins, using axios 
  useEffect(() => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/opanda/stats/findUsersActivitiesThisWeek`, {
        schoolID: userAuth.user.id
      },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.results[0][0].data.weeklyStats);
        setActivityData(response.data.results[0][0].data.weeklyStats.map((activity) => {
          // Randomize the color of the pie chart
          const name = activity.endpoint.split("/")[1];
          const name1 = activity.endpoint.split("/")[2];
          const color = "#" + Math.floor(Math.random() * 16777215).toString(16);
          return { name: name + "/" + name1, value: activity.count, color: color, fill: color };
        }).filter((activity) => activity.value > 0 && !activity.name.includes("findUsersActivitiesThisWeek") && !activity.name.toLowerCase().includes("nofif") && !activity.name.toLowerCase().includes("undefin") && !activity.name.toLowerCase().includes("packag") && !activity.name.toLowerCase().includes("newnotif")));
      })
      .catch((error) => {
        console.log(error);
      });
    const interval = setInterval(() => {
      axios
        .put(`${process.env.REACT_APP_BACKEND_URL}/opanda/stats/findUsersActivitiesThisWeek`,
          { schoolID: userAuth.user.id },

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userAuth.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.results[0][0].data.weeklyStats);
          setActivityData(response.data.results[0][0].data.weeklyStats.map((activity) => {
            // Randomize the color of the pie chart
            const name = activity.endpoint.split("/")[1];
            const name1 = activity.endpoint.split("/")[2];
            return { name: name + "/" + name1, value: parseInt(activity.count), color: "#" + Math.floor(Math.random() * 16777215).toString(16) };
          })).filter((activity) => activity.value > 0 && !activity.name.includes("findUsersActivitiesThisWeek") && !activity.name.toLowerCase().includes("nofif") && !activity.name.toLowerCase().includes("undefin") && !activity.name.toLowerCase().includes("packag") && !activity.name.toLowerCase().includes("newnotif"));
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1800000);
    return () => clearInterval(interval);
  }, []);




  return (
    (activityData_.length > 0) ? <div className="flex flex-wrap gap-4 p-4 w-full">
      {/* Pie Chart Section */}
      <div className="w-full md:w-1/2 flex flex-col items-center pl-80">
        <CardTitle name="Activity report" color="blue" alignment="left" />
        <PieChart width={500} height={500}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={activityData_}
            cx={250}
            cy={250}
            innerRadius={100}
            outerRadius={200}
            paddingAngle={5}
            label
          />
          <Tooltip />
        </PieChart>
      </div>

      {/* Activities Section */}
      {(activityData_.length > 0) ? <div className="w-full md:w-1/2 flex flex-col items-center pl-80">
        <CardBody name="Top Activities" color="blue" />
        <div className="space-y-2">
          {activityData_.map((activity, index) => (
            <div
              key={index}
              className="flex flex-row items-start space-x-2"
            >
              {/* Legend Rectangle */}
              <BiRectangle
                style={{
                  backgroundColor: activity.color,
                  height: "24px",
                  width: "24px",
                }}
              />
              {/* Legend Text */}
              <CardSubText name={`${activity.name}: ${activity.value}`} />
            </div>
          ))}
        </div>
      </div> : ""}
    </div> : <div>No recent activities!!!</div>
  );
};

export default StatsComponent;
